import React from 'react';
import './ArticleList.css';
import ListItem from '../ListItem';
import uniqueId from 'react-html-id';

class SeriesList extends React.Component {
  constructor(props) {
    super(props);

    uniqueId.enableUniqueIds(this);
  }

  render() {
    return (
      <div className="c-article-list">
        {this.props.children.edges.map(({ node }) => {
          return (
            <ListItem
              listTitle={node.frontmatter.title}
              publishedDate={node.frontmatter.date}
              url={node.fields.slug}
              key={this.nextUniqueId()}
            />
          );
        })}
      </div>
    );
  }
}

export default SeriesList;
