import React from 'react';
import Page from '../../components/Page';
import get from 'lodash/get';
import './topic.scss';
import rehypeReact from 'rehype-react';
import Grid from '../../components/Grid';
import Footer from '../../components/Footer';
import SmallHeader from '../../components/SmallHeader';
import SeriesList from '../../components/SeriesList';
import { ImageCaption } from '../../components/ImageCaption';
import { graphql } from 'gatsby';

function SeriesComponent(series) {
  if (series !== null) {
    return <SeriesList>{series}</SeriesList>;
  }
}

class TopicTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const seriesInTopic = this.props.data.allMarkdownRemark;
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');

    const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: {
        'image-caption': ImageCaption,
      },
    }).Compiler;

    return (
      <Page title={`${post.frontmatter.title} | ${siteTitle}`}>
        <Grid>
          <div className="c-individual-post">
            <SmallHeader />
            <h1>{post.frontmatter.title}</h1>
            {renderAst(post.htmlAst)}
            {SeriesComponent(seriesInTopic)}
          </div>
          <Footer />
        </Grid>
      </Page>
    );
  }
}

export default TopicTemplate;

export const query = graphql`
  query($slug: String!, $topicname: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        summary
        posttype
        topicname
        seriesname
        topicurl
        seriesurl
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          topicname: { eq: $topicname }
          posttype: { eq: "series" }
          published: { eq: true }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            posttype
            topicname
            seriesname
          }
        }
      }
    }
  }
`;
